/** @format */

import React from "react";
import FormularioCompras from "../../components/FormularioDireccion";
import Layout from "../../components/Layout";

function Formulario() {
  return (
    <Layout>
      <div className="formulario-contenedor">
        <div className="comprar-informacion-envio">
          <h2>Ingresa la información para el envío de productos: </h2>
          <FormularioCompras></FormularioCompras>
        </div>
      </div>
    </Layout>
  );
}

export default Formulario;
