/** @format */
import { navigate } from "gatsby-link";
import React, { useContext, useEffect, useState } from "react";
import { Form, Col, InputGroup, Button } from "react-bootstrap";
import { AppContext } from "../utils/ContextWrapper";
import { emailValidator } from "../utils/validaciones";

export default function FormularioCompras() {
  const departamentos = [
    "Alta Verapaz",
    "Baja Verapaz",
    "Chimaltenango",
    "Chiquimula",
    "El Progreso",
    "Escuintla",
    "Guatemala",
    "Huehuetenango",
    "Izabal",
    "Jalapa",
    "Jutiapa",
    "Petén",
    "Quetzaltenango",
    "Quiché",
    "Retalhuleu",
    "Sacatepéquez",
    "San Marcos",
    "Santa Rosa",
    "Sololá",
    "Suchitepéquez",
    "Totonicapán",
    "Zacapa",
  ];
  const municipios = {
    "Alta Verapaz": [
      "Cahabón",
      "Chahal",
      "Chisec",
      "Cobán",
      "Fray Bartolomé de las Casas",
      "Lanquín",
      "Panzós",
      "Raxruha",
      "San Cristóbal Verapaz",
      "San Juan Chamelco",
      "San Pedro Carchá",
      "Santa Cruz Verapaz",
      "Senahú",
      "Tactic",
      "Tamahú",
      "Tucurú",
      "Santa Catarina La Tinta",
    ],
    "Baja Verapaz": [
      "Cubulco",
      "Granados",
      "Purulhá",
      "Rabinal",
      "Salamá",
      "San Jerónimo",
      "San Miguel Chicaj",
      "Santa Cruz El Chol",
    ],
    Chimaltenango: [
      "Acatenango",
      "Chimaltenango",
      "El Tejar",
      "Parramos",
      "Patzicía",
      "Patzún",
      "Pochuta",
      "San Andrés Itzapa",
      "San José Poaquil",
      "San Juan Comalapa",
      "San Martín Jilotepeque",
      "Santa Apolonia",
      "Santa Cruz Balanyá",
      "Tecpán",
      "Yepocapa",
      "Zaragoza",
    ],
    Chiquimula: [
      "Camotán",
      "Chiquimula",
      "Concepción Las Minas",
      "Esquipulas",
      "Ipala",
      "Jocotán",
      "Olopa",
      "Quezaltepeque",
      "San Jacinto",
      "San José La Arada",
      "San Juan Ermita",
    ],
    "El Progreso": [
      "El Jícaro",
      "Guastatoya",
      "Morazán",
      "San Agustín Acasaguastlán",
      "San Antonio La Paz",
      "San Cristóbal Acasaguastlán",
      "Sanarate",
    ],
    Escuintla: [
      "Escuintla",
      "Guanagazapa",
      "Iztapa",
      "La Democracia",
      "La Gomera",
      "Masagua",
      "Nueva Concepción",
      "Palín",
      "San José",
      "San Vicente Pacaya",
      "Santa Lucía Cotzumalguapa",
      "Siquinalá",
      "Tiquisate",
    ],
    Guatemala: [
      "Amatitlán",
      "Chinautla",
      "Chuarrancho",
      "Fraijanes",
      "Guatemala",
      "Mixco",
      "Palencia",
      "Petapa",
      "San José del Golfo",
      "San José Pinula",
      "San Juan Sacatepéquez",
      "San Pedro Ayampuc",
      "San Pedro Sacatepéquez",
      "San Raymundo",
      "Santa Catarina Pinula",
      "Villa Canales",
    ],
    Huehuetenango: [
      "Aguacatán",
      "Chiantla",
      "Colotenango",
      "Concepción Huista",
      "Cuilco",
      "Huehuetenango",
      "Ixtahuacán",
      "Jacaltenango",
      "La Democracia",
      "La Libertad",
      "Malacatancito",
      "Nentón",
      "San Antonio Huista",
      "San Gaspar Ixchil",
      "San Juan Atitán",
      "San Juan Ixcoy",
      "San Mateo Ixtatán",
      "San Miguel Acatán",
      "San Pedro Necta",
      "San Rafael La Independencia",
      "San Rafael Petzal",
      "San Sebastián Coatán",
      "San Sebastián Huehuetenango",
      "Santa Ana Huista",
      "Santa Bárbara",
      "Santa Cruz Barillas",
      "Santa Eulalia",
      "Santiago Chimaltenango",
      "Soloma",
      "Tectitán",
      "Todos Santos Cuchumatan",
    ],
    Izabal: ["El Estor", "Livingston", "Los Amates", "Morales", "Puerto Barrios"],
    Jutiapa: [
      "Agua Blanca",
      "Asunción Mita",
      "Atescatempa",
      "Comapa",
      "Conguaco",
      "El Adelanto",
      "El Progreso",
      "Jalpatagua",
      "Jerez",
      "Jutiapa",
      "Moyuta",
      "Pasaco",
      "Quezada",
      "San José Acatempa",
      "Santa Catarina Mita",
      "Yupiltepeque",
      "Zapotitlán",
    ],
    Petén: [
      "Dolores",
      "Flores",
      "La Libertad",
      "Melchor de Mencos",
      "Poptún",
      "San Andrés",
      "San Benito",
      "San Francisco",
      "San José",
      "San Luis",
      "Santa Ana",
      "Sayaxché",
      "Las Cruces",
    ],
    Quetzaltenango: [
      "Almolonga",
      "Cabricán",
      "Cajolá",
      "Cantel",
      "Coatepeque",
      "Colomba",
      "Concepción Chiquirichapa",
      "El Palmar",
      "Flores Costa Cuca",
      "Génova",
      "Huitán",
      "La Esperanza",
      "Olintepeque",
      "Ostuncalco",
      "Palestina de Los Altos",
      "Quetzaltenango",
      "Salcajá",
      "San Carlos Sija",
      "San Francisco La Unión",
      "San Martín Sacatepéquez",
      "San Mateo",
      "San Miguel Sigüilá",
      "Sibilia",
      "Zunil",
    ],
    Quiché: [
      "Canillá",
      "Chajul",
      "Chicamán",
      "Chiché",
      "Chichicastenango",
      "Chinique",
      "Cunén",
      "Ixcán",
      "Joyabaj",
      "Nebaj",
      "Pachalum",
      "Patzité",
      "Sacapulas",
      "San Andrés Sajcabajá",
      "San Antonio Ilotenango",
      "San Bartolomé Jocotenango",
      "San Juan Cotzal",
      "San Pedro Jocopilas",
      "Santa Cruz del Quiché",
      "Uspantán",
      "Zacualpa",
    ],
    Retalhuleu: [
      "Champerico",
      "El Asintal",
      "Nuevo San Carlos",
      "Retalhuleu",
      "San Andrés Villa Seca",
      "San Felipe",
      "San Martín Zapotitlán",
      "San Sebastián",
      "Santa Cruz Muluá",
    ],
    Sacatepéquez: [
      "Alotenango",
      "Antigua",
      "Ciudad Vieja",
      "Jocotenango",
      "Magdalena Milpas Altas",
      "Pastores",
      "San Antonio Aguas Calientes",
      "San Bartolomé Milpas Altas",
      "San Lucas Sacatepéquez",
      "San Miguel Dueñas",
      "Santa Catarina Barahona",
      "Santa Lucía Milpas Altas",
      "Santa María de Jesús",
      "Santiago Sacatepéquez",
      "Santo Domingo Xenacoj",
      "Sumpango",
    ],
    "San Marcos": [
      "Ayutla",
      "Catarina",
      "Comitancillo",
      "Concepción Tutuapa",
      "El Quetzal",
      "El Rodeo",
      "El Tumbador",
      "Esquipulas Palo Gordo",
      "Ixchiguan",
      "La Reforma",
      "Malacatán",
      "Nuevo Progreso",
      "Ocos",
      "Pajapita",
      "Río Blanco",
      "San Antonio Sacatepéquez",
      "San Cristóbal Cucho",
      "San José Ojetenam",
      "San Lorenzo",
      "San Marcos",
      "San Miguel Ixtahuacán",
      "San Pablo",
      "San Pedro Sacatepéquez",
      "San Rafael Pie de La Cuesta",
      "San Sibinal",
      "Sipacapa",
      "Tacaná",
      "Tajumulco",
      "Tejutla",
    ],
    Jalapa: [
      "Jalapa",
      "Mataquescuintla",
      "Monjas",
      "San Carlos Alzatate",
      "San Luis Jilotepeque",
      "San Pedro Pinula",
      "San Manuel Chaparrón",
    ],
    "Santa Rosa": [
      "Barberena",
      "Casillas",
      "Chiquimulilla",
      "Cuilapa",
      "Guazacapán",
      "Nueva Santa Rosa",
      "Oratorio",
      "Pueblo Nuevo Viñas",
      "San Juan Tecuaco",
      "San Rafael Las Flores",
      "Santa Cruz Naranjo",
      "Santa María Ixhuatán",
      "Santa Rosa de Lima",
      "Taxisco",
    ],
    Sololá: [
      "Concepción",
      "Nahualá",
      "Panajachel",
      "San Andrés Semetabaj",
      "San Antonio Palopó",
      "San José Chacaya",
      "San Juan La Laguna",
      "San Lucas Tolimán",
      "San Marcos La Laguna",
      "San Pablo La Laguna",
      "San Pedro La Laguna",
      "Santa Catarina Ixtahuacan",
      "Santa Catarina Palopó",
      "Santa Clara La Laguna",
      "Santa Cruz La Laguna",
      "Santa Lucía Utatlán",
      "Santa María Visitación",
      "Santiago Atitlán",
      "Sololá",
    ],
    Suchitepéquez: [
      "Chicacao",
      "Cuyotenango",
      "Mazatenango",
      "Patulul",
      "Pueblo Nuevo",
      "Río Bravo",
      "Samayac",
      "San Antonio Suchitepéquez",
      "San Bernardino",
      "San Francisco Zapotitlán",
      "San Gabriel",
      "San José El Idolo",
      "San Juan Bautista",
      "San Lorenzo",
      "San Miguel Panán",
      "San Pablo Jocopilas",
      "Santa Bárbara",
      "Santo Domingo Suchitepequez",
      "Santo Tomas La Unión",
      "Zunilito",
    ],
    Totonicapán: [
      "Momostenango",
      "San Andrés Xecul",
      "San Bartolo",
      "San Cristóbal Totonicapán",
      "San Francisco El Alto",
      "Santa Lucía La Reforma",
      "Santa María Chiquimula",
      "Totonicapán",
    ],
    Zacapa: [
      "Cabañas",
      "Estanzuela",
      "Gualán",
      "Huité",
      "La Unión",
      "Río Hondo",
      "San Diego",
      "Teculután",
      "Usumatlán",
      "Zacapa",
    ],
  };

  const [loading, setLoading] = useState(false);
  const { actions, store } = useContext(AppContext);

  const [errores, setErrores] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    nit: "",
    direccion: "",
    departamento: "",
    codigoPostal: "",
    aceptaTerminos: "",
  });

  const [registro, setRegistro] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    nit: "",
    direccion: "",
    departamento: "Guatemala",
    codigoPostal: "Guatemala",
    aceptaTerminos: false,
  });

  const handleSubmit = () => {
    let valido = true;
    // se limpian los errores.
    let validacionErrores = {
      nombre: "",
      apellido: "",
      email: "",
      telefono: "",
      direccion: "",
      nit: "",
      departamento: "",
      codigoPostal: "",
      aceptaTerminos: "",
    };

    // se revisa que se han validado las propiedades.
    if (registro.nombre.length === 0) {
      validacionErrores.nombre = "El campo nombre es requerido";
      valido = false;
    }
    if (registro.apellido.length === 0) {
      validacionErrores.apellido = "El campo apellido es requerido";
      valido = false;
    }
    if (registro.email.length === 0) {
      validacionErrores.email = "El campo de correo es requerido";
      valido = false;
    }
    if (registro.nit.length === 0) {
      validacionErrores.nit = "El NIT es requerido";
      valido = false;
    }
    if (registro.telefono.length === 0) {
      validacionErrores.telefono = "El de telefono es requerido";
      valido = false;
    }
    if (registro.direccion.length === 0) {
      validacionErrores.direccion = "La direccion es requerida";
      valido = false;
    }
    if (registro.departamento?.length === 0) {
      validacionErrores.departamento = "El departamento es requerido";
      valido = false;
    }
    if (registro.codigoPostal?.length === 0) {
      validacionErrores.codigoPostal = "El municipio es requerido";
      valido = false;
    }
    if (registro.aceptaTerminos === false) {
      validacionErrores.aceptaTerminos = "Debes aceptar los términos y condiciones";
      valido = false;
    }
    if (registro.telefono.length !== 8) {
      validacionErrores.telefono = "Ingesa un número de teléfono correcto de 8 digitos. Ej: 99999999";
      valido = false;
    }

    /*if (registro.codigoPostal?.length !== 5) {
      validacionErrores.codigoPostal = "Ingesa un código postal correcto de 5 digitos. Ej: 01012";
      valido = false;
    }*/

    if (!emailValidator.test(registro.email)) {
      validacionErrores.email = "Ingresa un correo válido. Ej: tucorreo@direccion.com";
      valido = false;
    }

    setErrores(validacionErrores);

    if (valido) {
      actions.guardarDatosEnvio(registro);
      navigate("/comprar/pago/");
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "aceptaTerminos") setRegistro({ ...registro, [event.target.name]: event.target.checked });
    else if (event.target.name === "departamento") {
      console.log("Entro al cambio");
      setRegistro({ ...registro, codigoPostal: "", [event.target.name]: event.target.value });
    } else setRegistro({ ...registro, [event.target.name]: event.target.value });

    if (event.target.name === "email") {
      if (!emailValidator.test(event.target.value))
        setErrores({ ...errores, email: "Ingresa un correo válido. Ej: tucorreo@direccion.com" });
      else setErrores({ ...errores, email: "" });
    } else if (event.target.name === "telefono") {
      if (event.target.value.length === 8) setErrores({ ...errores, telefono: "" });
      else setErrores({ ...errores, telefono: "Ingesa un número de teléfono correcto de 8 digitos. Ej: 99999999" });
      /*} else if (event.target.name === "codigoPostal") {
      if (event.target.value.length === 5) setErrores({ ...errores, codigoPostal: "" });
      else setErrores({ ...errores, codigoPostal: "Ingesa un código postal correcto de 5 digitos. Ej: 01012" });*/
    } else if (event.target.value.length === 0)
      setErrores({ ...errores, [event.target.name]: "El campo es obligatorio" });
    else setErrores({ ...errores, [event.target.name]: "" });
  };

  useEffect(() => {
    // si el usuario
    if (store.usuarioAutenticado.username) {
      setLoading(true);
      fetch(
        `${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/user/info/${store.usuarioAutenticado.username}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${store.usuarioAutenticado.token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
        .then((result) => result.json())
        .then((result) => {
          setLoading(false);
          setRegistro({ ...registro, ...result });
        })
        .catch((error) => {
          setLoading(false);
        });
    }

    if (!store.sid) {
      fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/sid`)
        .then((result) => result.json())
        .then((result) => {
          actions.setSid(result.sid);
        });
    }

    if (actions.obtenerTotalProductos(store.carrito) === 0) {
      navigate("/comprar/");
      return null;
    }
  }, [store.usuarioAutenticado.username, store.carrito]);

  return (
    <Form>
      <Form.Group as={Col} md="12" controlId="validaNombre">
        <Form.Label>Nombre:</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            placeholder="Ingresa tu nombre"
            aria-describedby="inputGroupPrepend"
            required
            name="nombre"
            value={registro.nombre}
            onChange={handleChange}
            isInvalid={errores.nombre.length > 0}
          />
          <Form.Control.Feedback type="invalid">{errores.nombre}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group as={Col} md="12" controlId="validationApellido">
        <Form.Label>Apellido:</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            placeholder="Ingresa tu apellido"
            aria-describedby="inputGroupPrepend"
            required
            name="apellido"
            value={registro.apellido}
            onChange={handleChange}
            isInvalid={errores.apellido.length > 0}
          />
          <Form.Control.Feedback type="invalid">{errores.apellido}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="validationNit">
        <Form.Label>NIT :</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="nit"
            placeholder="Ingresa tu NIT (Para emisión de factura)"
            aria-describedby="inputGroupPrepend"
            required
            name="nit"
            value={registro.nit}
            onChange={handleChange}
            isInvalid={errores.nit.length > 0}
          />
          <Form.Control.Feedback type="invalid">{errores.nit}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="validationEmail">
        <Form.Label>Correo electrónico:</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="email"
            placeholder="Ingresa tu correo"
            aria-describedby="inputGroupPrepend"
            required
            name="email"
            value={registro.email}
            onChange={handleChange}
            isInvalid={errores.email.length > 0}
          />
          <Form.Control.Feedback type="invalid">{errores.email}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group as={Col} md="12" controlId="validationTelephone">
        <Form.Label>Teléfono :</Form.Label>
        <InputGroup hasValidation>
          <InputGroup.Text id="inputGroupPrepend">+(502)</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Teléfono de contacto"
            aria-describedby="inputGroupPrepend"
            required
            name="telefono"
            value={registro.telefono}
            onChange={handleChange}
            isInvalid={errores.telefono.length > 0}
          />
          <Form.Control.Feedback type="invalid">{errores.telefono}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="validationCustom03">
        <Form.Label>Dirección de entrega :</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ingresa tu dirección completa, departamento y municipio"
          required
          name="direccion"
          value={registro.direccion}
          onChange={handleChange}
          isInvalid={errores.direccion.length > 0}
        />
        <Form.Control.Feedback type="invalid">{errores.direccion}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="validationCustom03">
        <Form.Label>Departamento :</Form.Label>
        <Form.Select
          placeholder="Ingresa Departamento"
          required
          name="departamento"
          onChange={handleChange}
          value={registro.departamento}
          isInvalid={errores.departamento.length > 0}
          disabled={store.envio <= 3}
        >
          {departamentos.map((llave) => {
            return <option value={llave}>{llave}</option>;
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{errores.departamento}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="12" controlId="validationCustom03">
        <Form.Label>Municipio:</Form.Label>
        <Form.Select
          placeholder="Ingresa el municipio"
          required
          name="codigoPostal"
          onChange={handleChange}
          value={registro.codigoPostal}
          isInvalid={errores.codigoPostal.length > 0}
        >
          <option>Selecciona un municipio</option>
          {municipios[registro.departamento].map((llave) => {
            return <option value={llave}>{llave}</option>;
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{errores.codigoPostal}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="chkPoliticas">
        <Form.Check
          type="checkbox"
          className="comprar-chk-formulario"
          label="Estoy de acuerdo con los términos y condiciones."
          name="aceptaTerminos"
          onChange={handleChange}
          isInvalid={errores.aceptaTerminos.length > 0}
        />
        <Form.Control.Feedback type="invalid">{errores.aceptaTerminos}</Form.Control.Feedback>
      </Form.Group>
      <Button onClick={handleSubmit} variant="primary">
        {loading ? "Procesando.." : "Terminar pedido"}
      </Button>
    </Form>
  );
}
